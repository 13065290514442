import React, { useEffect, useState } from "react"
import fetch from "cross-fetch"
import { useAuth0 } from "@auth0/auth0-react"
import QueryString from "query-string"
import { navigate } from "gatsby"

const TestSoisy = ({ location }) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const { getAccessTokenSilently } = useAuth0()
  const params = QueryString.parse(location.search)
  const { id } = params

  useEffect(() => {
    const getSoisyOrder = async () => {
      const authToken = await getAccessTokenSilently()
      const response = await fetch("/.netlify/functions/klarna-create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ purchaseId: id }),
      })
      if (response.status === 200) {
        const res = await response.json()
        console.log(res)
        setRedirectUrl(res.redirect_url)
        await navigate(res.redirect_url, { replace: true })
      }
    }
    getSoisyOrder().catch(console.error)
  }, [])

  return (
    <>
      <div>{redirectUrl && <a href={redirectUrl}>Paga</a>}</div>
    </>
  )
}

export default TestSoisy
